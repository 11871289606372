import { AuthService, TokenDTO } from '@services/auth.service'
import {
    BehaviorSubject,
    Observable,
    catchError, filter, finalize, from, map, switchMap, take, tap, throwError,
} from 'rxjs'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'

@Injectable({
    providedIn: 'root',
})
export class AuthErrorInterceptor implements HttpInterceptor {

    private isRefreshingToken = false
    private tokenDataSubject = new BehaviorSubject<TokenDTO | Error | null>(null)

    constructor(private authService: AuthService) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error) => {
                if (error.status !== 401) {
                    throw error
                }
                return this.refreshAccessToken()
                    .pipe(
                        catchError(_error => {
                            if (error.status === 400) {
                                throw this.logoutAndRelayError(error)
                            }
                            throw error
                        }),
                        switchMap(token => {
                            const newRequest = this.newAuthHeaderInRequest(request, token)
                            return next.handle(newRequest)
                        })
                    )
            }),
            catchError(error => {
                if (error.status === 401) {
                    throw this.logoutAndRelayError(error)
                }
                throw error
            })
        )
    }

    private refreshAccessToken(): Observable<TokenDTO> {
        if (this.isRefreshingToken) {
            return this.tokenDataSubject.pipe(
                filter(tokenData => tokenData !== null),
                map(tokenData => {
                    if (tokenData instanceof Error) {
                        throw tokenData
                    }
                    return tokenData as TokenDTO
                }),
                take(1)
            )
        }
        this.isRefreshingToken = true
        this.tokenDataSubject.next(null)
        return from(this.authService.refreshToken())
            .pipe(
                tap(tokenData => {
                    this.tokenDataSubject.next(tokenData)
                }),
                catchError(error => {
                    this.tokenDataSubject.next(error)
                    return throwError(() => error)
                }),
                finalize(() => {
                    this.isRefreshingToken = false
                })
            )
    }

    private logoutAndRelayError(error: Error) {
        this.authService.logout()
        return error
    }

    private newAuthHeaderInRequest(request: HttpRequest<any>, tokenData: TokenDTO) {
        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${tokenData.access_token}`,
            },
        })
    }
}
