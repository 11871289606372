import { AuthCallbackComponent } from './components/auth-callback/auth-callback.component'
import { AuthErrorInterceptor } from '../auth/AuthErrorInterceptor'
import { AuthProvidingInterceptor } from '../auth/AuthProvidingInterceptor'
import { HTTPErrorOverwriteInterceptor } from '../HTTPErrorOverwriteInterceptor'
import { HTTPHeaderInterceptor } from '@app/HTTPHeaderInterceptor'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { PATHS } from '@app/routes'
import { RouterModule, Routes } from '@angular/router'
import { authGuard } from '@app/auth/auth.guard'

const routes: Routes = [
    {
        path: PATHS.authCallback,
        component: AuthCallbackComponent,
    },
    {
        path: PATHS.activity,
        loadChildren: () => import('../features/activity/activity.module')
            .then(m => m.ActivityModule),
        canActivate: [authGuard],
    },
    {
        path: PATHS.bookings,
        loadChildren: () => import('../features/bookings/bookings.module')
            .then(mod => mod.BookingsModule),
        canActivate: [authGuard],
    },
    {
        path: PATHS.management,
        pathMatch: 'prefix',
        loadChildren: () => import('../features/management/management.module')
            .then(mod => mod.ManagementModule),
        canActivate: [authGuard],
    },
    {
        path: PATHS.reports,
        loadChildren: () => import('../features/reports/reports.module')
            .then(mod => mod.ReportsModule),
        canActivate: [authGuard],
    },
    {
        path: PATHS.profile,
        loadChildren: () => import('../features/profile/profile.module')
            .then(m => m.ProfileModule),
        canActivate: [authGuard],
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: PATHS.diary,
    },
    {
        path: '**',
        redirectTo: PATHS.diary,
    },
]
@NgModule({
    imports: [
        RouterModule.forRoot(
            routes, {
                bindToComponentInputs: true,
            }),
    ],
    exports: [RouterModule],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HTTPErrorOverwriteInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HTTPHeaderInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthErrorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthProvidingInterceptor,
            multi: true,
        },
    ],
})
export class AppRoutingModule { }
