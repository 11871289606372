import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable()
export class HTTPHeaderInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let headers = request.headers as HttpHeaders

        if (!headers.has('Content-Type')) {
            if (request.method === 'PATCH') {
                headers = headers.append('Content-Type', 'application/json-patch+json')
            }
        }
        //TODO: This is odd! Fix or remove in the future
        if (!headers.has('Access-Control-Allow-Origin')) {
            headers = headers.append('Access-Control-Allow-Origin', '*')
        }

        return next.handle(request.clone({ headers }))
    }
}
