import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, catchError } from 'rxjs'

export interface ErrorResponse {
    status: number
    title: string
    message: string
}

@Injectable({
    providedIn: 'root',
})
export class HTTPErrorOverwriteInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err) => {
                let error: ErrorResponse
                if (err.error instanceof ErrorEvent) {
                    // client-side error
                    error = {
                        status: 700,
                        title: err.error, //might be wrong??
                        message: err.error.message,
                    }
                } else {
                    // server-side error
                    error = {
                        status: err.status,
                        title: err.statusText,
                        message: err.message,
                    }
                }
                throw error
            })
        )
    }
}
