<app-screen-size-detector></app-screen-size-detector>

<ng-container *appIfLoggedIn="loggedIn; else notLoggedIn">
</ng-container>

<ng-template #loggedIn>
    <div class="vh-100-screen-safe vstack">
        @if (toolbarService.toolbarPlacement$ | async; as toolbarPlacement) {
            <div class="toast-container position-fixed top-0 end-0 px-3 py-2 m-16 mt-5" style="z-index: 1200">
                <app-toasts></app-toasts>
            </div>
            <div class="hstack align-items-stretch">
                @if (toolbarPlacement === 'side') {
                    <div
                        class="d-none d-lg-flex flex-column px-0 border-end border-1 border-light-subtle"
                    >
                        <div app-menu class="vstack">
                        </div>
                    </div>
                }
                <div class="vstack flex-grow-1 vh-100-screen-safe overflow-auto">
                    <app-toolbar>
                    </app-toolbar>
                    <div class="overflow-y-auto overflow-x-hidden vstack align-items-stretch">
                        <app-subscription-banner>
                        </app-subscription-banner>
                        <div class="bg-light p-0 flex-fill">
                            <router-outlet>
                            </router-outlet>
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>
</ng-template>

<ng-template #notLoggedIn>
    <div class="d-flex justify-content-center bg-light vh-100-screen-safe">
        <div class="my-5 spinner-border text-dark" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <router-outlet></router-outlet>
</ng-template>
