/*
 * Copyright 2020 New Relic Corporation. All rights reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

import { InstrumentBase } from '../../utils/instrument-base';
import { FEATURE_NAME } from '../constants';
export class Instrument extends InstrumentBase {
  static featureName = FEATURE_NAME;
  constructor(agentIdentifier, aggregator) {
    let auto = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    super(agentIdentifier, aggregator, FEATURE_NAME, auto);
    this.importAggregator();
  }
}