import { NumberPublisher } from '@app/domain/NumberPublisher'
import { Venue } from '@app/domain/Venue'

export class MenuItem {
    constructor(
        public title: string,
        public icon: string,
        public route: string,
        public hidden = false,
        public notificationCountPublisher?: NumberPublisher,
        private hideVenueEvaluator?: (venue: Venue) => boolean
    ) { }

    configure(venue: Venue) {
        this.hidden = this.hideVenueEvaluator ? this.hideVenueEvaluator(venue) : false
    }
}
