import { BillingDetails } from '@app/domain/BillingDetails'
import { BillingService } from '@services/billing.service'
import { Component, OnInit } from '@angular/core'
import { Observable } from 'rxjs'

@Component({
    selector: 'app-subscription-banner',
    templateUrl: './subscription-banner.component.html',
    styleUrls: ['./subscription-banner.component.sass'],
})
export class SubscriptionBannerComponent implements OnInit {

    billingDetails$: Observable<BillingDetails>

    constructor(
        private billingService: BillingService
    ) {
        this.billingDetails$ = billingService.getBillingDetails()
    }

    ngOnInit() { }

    upgradeTrialClicked() {
        this.billingService.openCheckoutTrialPortal(
            () => {
                this.billingDetails$ = this.billingService.getBillingDetails()
            }
        )
    }

    resumeSubscriptionClicked() {
        this.billingService.openManageSubscriptionPortal(
            () => {
                this.billingDetails$ = this.billingService.getBillingDetails()
            }
        )
    }
}
