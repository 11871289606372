import { Component, OnDestroy, OnInit } from '@angular/core'
import { Toast, ToastType } from '@app/domain/Toast'
import { ToastService } from '@services/toast.service'

@Component({
    selector: 'app-toasts',
    templateUrl: './toasts.component.html',
    styleUrls: ['./toasts.component.sass'],
})
export class ToastsComponent implements OnInit, OnDestroy {
    keepall = true

    constructor(public toastService: ToastService) {}

    ngOnInit() { }

    onDismissToast(toast: Toast) {
        this.toastService.remove(toast)
    }

    ngOnDestroy(): void {
        this.toastService.clear()
    }

    protected readonly ToastType = ToastType
}
