import { Component, Input, OnInit } from '@angular/core'
import { ContextService } from '@services/context.service'
import { HttpParams } from '@angular/common/http'
import { Observable, map } from 'rxjs'
import { User } from '@app/domain/User'
import { UserService } from '@services/user.service'

const feedbackURL = 'https://docs.google.com' +
    '/forms/d/e/1FAIpQLSftmLRyLWTvAXmpSn2NAKG0xNyYgYshU1lgA3PxQy1O9TE7ug/viewform'

@Component({
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.sass'],
})
export class FeedbackComponent implements OnInit {
    @Input() compact = false
    feedbackURL!: Observable<string>

    constructor(
        private userService: UserService,
        contextService: ContextService
    ) {
        this.feedbackURL = contextService.user$
            .pipe(
                map(user => this.makeFeedbackURL(user))
            )
    }

    ngOnInit(): void { }

    private makeFeedbackURL(user: User): string {
        const params = new HttpParams()
            .set('usp', 'pp_url')
            .set('entry.879531967', user.emailAddress)
            .set('entry.485428648', user.name)
        return feedbackURL + '?' + params.toString()
    }
}
