import { AppComponent } from './components/app/app.component'
import { AppRoutingModule } from './app-routing.module'
import { ApplicationRef, LOCALE_ID, NgModule, isDevMode } from '@angular/core'
import { AuthCallbackComponent } from './components/auth-callback/auth-callback.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { BrowserModule } from '@angular/platform-browser'
import {
    ExpiredBannerComponent,
} from '@app/root/components/expired-banner/expired-banner.component'
import { FeedbackComponent } from './components/feedback/feedback.component'
import { MenuComponent } from '@app/root/components/menu/menu.component'
import { NgOptimizedImage, registerLocaleData } from '@angular/common'
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { NgxPaginationModule } from 'ngx-pagination'
import { Observability } from '@app/helpers/utilities/observability'
import { ServiceWorkerModule } from '@angular/service-worker'
import { SharedModule } from '@app/features/shared/shared.module'
import {
    SubscriptionBannerComponent,
} from '@app/root/components/subscription-banner/subscription-banner.component'
import { ToastsComponent } from './components/toast/toasts.component'
import { ToolbarComponent } from './components/toolbar/toolbar.component'
import { TrialBannerComponent } from './components/trial-banner/trial-banner.component'
import { first } from 'rxjs'
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import gbLocale from '@angular/common/locales/en-GB'

registerLocaleData(gbLocale)

@NgModule({
    bootstrap: [
        AppComponent,
    ],
    declarations: [
        AppComponent,
        AuthCallbackComponent,
        ExpiredBannerComponent,
        FeedbackComponent,
        MenuComponent,
        SubscriptionBannerComponent,
        ToastsComponent,
        ToolbarComponent,
        TrialBannerComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        NgbDropdownModule,
        NgbModule,
        NgOptimizedImage,
        NgxPaginationModule,
        SharedModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
    ],
    providers: [
        {
            provide: LOCALE_ID,
            useValue: 'en-GB',
        },
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule {
    private observability: Observability

    constructor(
        private appRef: ApplicationRef
    ) {
        const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable))
        this.observability = new Observability()
        appIsStable$.subscribe(() => this.observability.setUp())
    }
}
