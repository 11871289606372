<ng-container
    *ngIf="{ value: showToolbar$ | async } as showToolbar"
    >
    <ng-container
        *ngIf="showToolbar.value"
        >
        <ng-container
            *ngTemplateOutlet="toolbar"
        >
        </ng-container>
    </ng-container>
</ng-container>

<ng-template
    #toolbar
>
    <div class="bg-body py-2 pb-md-2 px-2 px-md-3 hstack gap-3">
        @if (showMenu$ | async) {
            <button
                class="btn btn-outline-secondary d-flex position-relative px-2"
                data-testid="menu-button"
                (click)="openOffCanvasMenu()"
            >
                <img
                    ngSrc="assets/menu/normal.svg"
                    alt="Settings"
                    width="24"
                    height="24"
                >
                <div
                    class="position-absolute"
                    style="right: -8px; top: -8px;"
                >
                    <ng-template
                        [ngTemplateOutlet]="badge"
                        [ngTemplateOutletContext]="{$implicit: requestedBookingsCountPublisher}"
                    >
                    </ng-template>
                </div>
            </button>
            <div class="vr"></div>
            <app-logo
                [compact]="true"
            >
            </app-logo>
        }
        <h5
            *ngIf="venue$ | async as venue"
            class="m-0 text-truncate py-2"
        >
            {{ venue.displayName }}
        </h5>
        <div class="d-none d-lg-block vr"></div>
        <div class="d-none d-lg-flex hstack gap-3 gap-lg-4">
            <nav>
                <ol class="breadcrumb m-0">
                    <li class="breadcrumb-item"
                        *ngFor="let breadcrumb of breadcrumbs$ | async; let last = last"
                        [ngClass]="{'active': last}"
                    >
                        <a
                            *ngIf="!last; else lastBreadcrumb"
                            [routerLink]="[breadcrumb.url]"
                        >
                            {{ breadcrumb.label }}
                        </a>
                        <ng-template #lastBreadcrumb>
                            {{ breadcrumb.label }}
                        </ng-template>
                    </li>
                </ol>
            </nav>
        </div>
    </div>
    <hr class="m-0"/>
</ng-template>

<ng-template #badge let-notificationCountPublisher>
    <ng-container
        *ngIf="notificationCountPublisher"
    >
        <div
            *ngIf="notificationCountPublisher.number$ | async as count"
            class="badge bg-danger top-0"
        >
            {{ count | notificationCount }}
        </div>
    </ng-container>
</ng-template>
