import { AppModule } from '@app/root/app.module'
import { enableProdMode } from '@angular/core'
import { environment } from '@environments/environment'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { registerLocaleData } from '@angular/common'
import enGBLocale from '@angular/common/locales/en-GB'

if (environment.production) {
    enableProdMode()
}

registerLocaleData(enGBLocale)

/* eslint no-console: ["error", { allow: ["warn"] }] */
platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.warn(err))
