import { ApplicationRef, Injectable } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { SwUpdate } from '@angular/service-worker'
import { concat, first, interval } from 'rxjs'
import { environment } from '@environments/environment'

@Injectable({
    providedIn: 'root',
})
export class UpdateService {

    constructor(
        private appRef: ApplicationRef,
        private modalService: NgbModal,
        private updates: SwUpdate
    ) {
        updates.unrecoverable.subscribe(event => {
            console.error('Unrecoverable error with the service worker:', event)
            document.location.reload()
        })
    }

    startCheckingForUpdates() {
        if (!environment.production) {
            return
        }
        const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable))
        const every30Minutes$ = interval(30 * 60 * 1000)
        const every30MinutesOnceAppIsStable$ = concat(appIsStable$, every30Minutes$)
        every30MinutesOnceAppIsStable$.subscribe(async () => {
            try {
                const updateFound = await this.updates.checkForUpdate()
                const hasOpenModal = this.modalService.hasOpenModals()
                if (updateFound && !hasOpenModal) {
                    document.location.reload()
                }
            } catch (err) {
                console.error('Failed to check for updates:', err)
            }
        })
    }
}
