<ng-container
    *ngIf="billingDetails$ | async as billingDetails"
>
    <app-trial-banner
        [billingDetails]="billingDetails"
        (upgradeTrialClicked)="upgradeTrialClicked()"
    >
    </app-trial-banner>
    <app-expired-banner
        [billingDetails]="billingDetails"
        (resumeSubscriptionClicked)="resumeSubscriptionClicked()"
    >
    </app-expired-banner>
</ng-container>
