import { BillingDetails } from '@app/domain/BillingDetails'
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core'
import { differenceInDays } from 'date-fns'

@Component({
    selector: 'app-trial-banner',
    templateUrl: './trial-banner.component.html',
    styleUrls: ['./trial-banner.component.sass'],
})
export class TrialBannerComponent implements OnInit, OnChanges {

    @Input() billingDetails!: BillingDetails
    @Output() upgradeTrialClicked = new EventEmitter<void>()
    bannerShown!: boolean
    daysLeftInTrial!: number
    daysLeftPluralMapping = {
        '=0': '0 days',
        '=1': '1 day',
        other: '# days',
    }
    private readonly DAYS_LEFT_IN_TRIAL_TO_SHOW_BANNER = 14

    ngOnInit() {}

    ngOnChanges() {
        this.refreshBanner()
    }

    clickUpgradeTrial() {
        this.upgradeTrialClicked.emit()
    }

    private refreshBanner() {
        this.bannerShown = this.isBannerShown()
        this.daysLeftInTrial = this.getDaysLeftInTrial()
    }

    private getDaysLeftInTrial(): number {
        if (this.billingDetails.nextBillingDate === null) {
            return 0
        }
        return differenceInDays(this.billingDetails.nextBillingDate, new Date())
    }

    private isBannerShown(): boolean {
        if (!this.billingDetails.needsToUpgradeTrial) {
            return false
        }
        if (this.getDaysLeftInTrial() <= this.DAYS_LEFT_IN_TRIAL_TO_SHOW_BANNER) {
            return true
        }
        return false
    }
}
