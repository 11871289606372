import { AuthService } from '@services/auth.service'
import { inject } from '@angular/core'

export const authGuard = () => {
    const authService = inject(AuthService)
    if (authService.getToken() === null) {
        authService.reauthorise()
        return false
    }
    return true
}
