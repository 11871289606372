import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { AuthService } from '@services/auth.service'
import {
    BehaviorSubject,
    Observable,
    Subject,
    combineLatestWith,
    first,
    map,
    takeUntil,
} from 'rxjs'
import {
    Component,
    HostBinding,
    Input,
    OnDestroy,
    OnInit, Optional,
    QueryList, TemplateRef,
    ViewChildren,
} from '@angular/core'
import { ContextService } from '@services/context.service'
import { Helper } from '@app/helpers/utilities/helper'
import { LocalStorageService } from '@services/local-storage-service'
import { MenuItem } from '@app/root/components/menu/menuItem'
import { NgbActiveOffcanvas, NgbNav, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap'
import { PATHS } from '@app/routes'
import { RequestedBookingsCountPublisher } from '@app/domain/RequestedBookingsCountPublisher'
import { ToolbarService } from '@services/toolbar.service'
import { Venue } from '@app/domain/Venue'

@Component({
    selector: 'app-menu, [app-menu]',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.sass'],
})
export class MenuComponent implements OnInit, OnDestroy {

    @Input() activeRoute!: string
    @ViewChildren(NgbNav) ngbNavs!: QueryList<NgbNav>
    @HostBinding('class') class = 'vstack h-100'
    appMenu: MenuItem[]
    accountMenu: MenuItem[] = [
        new MenuItem(
            'Management',
            'management',
            `${PATHS.management}`
        ),
        new MenuItem(
            'Account',
            'profile',
            PATHS.profile
        ),
    ]
    venue$!: Observable<Venue>
    compact = new BehaviorSubject<boolean>(false)
    compact$ = this.compact.asObservable()
    showCompactToggle$!: Observable<boolean>
    fixedWidth$!: Observable<boolean>
    private onDestroy$ = new Subject<void>()

    constructor(
        private authService: AuthService,
        private contextService: ContextService,
        private localStorageService: LocalStorageService,
        public requestedBookingsCountPublisher: RequestedBookingsCountPublisher,
        public route: ActivatedRoute,
        private router: Router,
        protected toolbarService: ToolbarService,
        @Optional() private offCanvas: NgbActiveOffcanvas | null,
        @Optional() public offCanvasService: NgbOffcanvas | null
    ) {
        this.appMenu = [
            new MenuItem(
                'Activity',
                'activity',
                PATHS.activity
            ),
            new MenuItem(
                'Diary',
                'diary',
                PATHS.diary
            ),
            new MenuItem(
                'Inbox',
                'inbox',
                PATHS.inbox,
                true,
                this.requestedBookingsCountPublisher,
                venue => {
                    return !venue.areas.some(area => area.scheduledForManualAcceptance)
                }
            ),
            new MenuItem(
                'Reports',
                'reports',
                PATHS.reports
            ),
        ]
    }

    get allMenuItems(): MenuItem[] {
        return this.appMenu
            .concat(this.accountMenu)
    }

    ngOnInit() {
        this.bindFixedWidthToBeingExpandedOnSideOrCompact()
        this.venue$ = this.contextService.venue$
        this.bindRouteToMenu()
        this.setMenuSizeToLocalStorage()
        this.bindVenueToMenuItems()
        this.bindShowCompactToggleToBeingOnSide()
    }

    ngOnDestroy() {
        this.onDestroy$.next()
        this.onDestroy$.complete()
    }

    openOffCanvasMenu(content: TemplateRef<any>) {
        this.offCanvasService?.open(content, { panelClass: 'w-75 overflow-auto' })
    }

    activeRouteChanged() {
        this.offCanvasService?.dismiss()
    }

    dismiss() {
        this.offCanvas?.dismiss()
    }

    hideSideMenu() {
        this.toolbarService.setToolbarPlacement('top')
        this.menuExpandClicked()
    }

    showSideMenu() {
        this.toolbarService.setToolbarPlacement('side')
        this.offCanvas?.dismiss()
    }

    menuExpandClicked() {
        this.compact.next(false)
        this.localStorageService.setItem('menuSize', 'expanded')
    }

    menuCollapseClicked() {
        this.compact.next(true)
        this.localStorageService.setItem('menuSize', 'compact')
    }

    logout() {
        this.authService.logout()
    }

    private bindRouteToMenu() {
        this.router.events.pipe(
            first(event => event instanceof NavigationEnd),
            map(event => event as NavigationEnd)
        )
            .subscribe(event => {
                const path = Helper.relativePath(event.url)
                const allMenuItems = this.appMenu
                    .concat(this.accountMenu)
                for (const menuItem of allMenuItems) {
                    this.selectMenuItemIfShown(menuItem, path)
                }
            })
    }

    private bindVenueToMenuItems() {
        this.venue$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(venue => {
                this.allMenuItems.forEach(menuItem => {
                    menuItem.configure(venue)
                })
            })
    }

    private bindFixedWidthToBeingExpandedOnSideOrCompact() {
        this.fixedWidth$ = this.toolbarService.toolbarPlacement$
            .pipe(
                takeUntil(this.onDestroy$),
                combineLatestWith(this.compact$),
                map(([placement, isCompact]) => {
                    return placement === 'side' && !isCompact
                })
            )
    }

    private bindShowCompactToggleToBeingOnSide() {
        this.showCompactToggle$ = this.toolbarService.toolbarPlacement$
            .pipe(
                takeUntil(this.onDestroy$),
                map(placement => placement === 'side')
            )
    }

    private selectMenuItemIfShown(item: MenuItem, path: string): boolean {
        if (path.startsWith(item.route)) {
            this.activeRoute = item.route
            return true
        }
        return false
    }

    private setMenuSizeToLocalStorage() {
        const menuSize = this.localStorageService.getItem('menuSize')
        if (menuSize === 'compact') {
            this.compact.next(true)
        } else if (menuSize === 'expanded') {
            this.compact.next(false)
        }
    }
}
