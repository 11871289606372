<div *ngFor="let toast of toastService.toasts" class="mb-2">
    <ngb-toast
        [class]="toast.getClass()"
        [header]="toast.header"
        [delay]="toast.delay"
        (hidden)="onDismissToast(toast)"
        [autohide]="toast.autohide ? keepall : false"
        (mouseenter)="keepall = false"
        (mouseleave)="keepall = true"
    >
        <ng-template ngbToastHeader>
            <div class="me-auto">
                <span class="fw-semibold fs-4">
                    {{ toast.header }}
                </span>
            </div>
        </ng-template>
        {{ toast.message }}
    </ngb-toast>
</div>
