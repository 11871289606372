<a
    type="button"
    class="btn btn-navigation hstack gap-3"
    [href]="feedbackURL | async"
    target="_blank"
    rel="noopener noreferrer"
    [ngbTooltip]="compact ? 'Send Feedback' : null"
    triggers="hover"
    placement="right"
>
    <img
        src="/assets/share/normal.svg"
    >
    <span
        *ngIf="!compact"
        class="flex-grow-1"
    >
        Send Feedback
    </span>
    <span
        *ngIf="compact"
        class="d-lg-none"
    >
        Send Feedback
    </span>
</a>
