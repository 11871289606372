@if ({ fixedWidth: (fixedWidth$ | async) }; as value) {
    @if ({ compact: (compact$ | async) }; as compact) {
        <div
            class="vstack menu vh-100-screen-safe"
            [ngClass]="value.fixedWidth ? 'fixed-width' : ''"
        >
            <ng-container
                [ngTemplateOutlet]="inlineMenu"
                [ngTemplateOutletContext]="{compact: compact.compact}"
            >
            </ng-container>
            <div class="mt-auto">
                <ng-container
                    [ngTemplateOutlet]="settings"
                    [ngTemplateOutletContext]="{compact: compact.compact}"
                >
                </ng-container>
            </div>
        </div>
    }
}

<ng-template #settingsDropdown>
    <div
        class="me-0"
        ngbDropdown
        display="dynamic"
    >
        <button
            class="btn btn-outline-secondary compact"
            ngbDropdownToggle
            type="button"
        >
            <img
                ngSrc="assets/settings/normal.svg"
                alt="Settings"
                width="24"
                height="24"
            >
        </button>
        <div
            class="d-print-none p-0"
            ngbDropdownMenu
        >
            <ng-container
                [ngTemplateOutlet]="settings"
                [ngTemplateOutletContext]="{compact: false}"
            >
            </ng-container>
        </div>
    </div>
</ng-template>

<ng-template
    #inlineMenu
    let-compact="compact"
>
    <div class="mt-4 mb-2 text-center">
        <app-logo
            [compact]="compact"
        >
        </app-logo>
    </div>
    <div class="overflow-auto">
    <ul
        class="p-1 p-lg-0 m-2 m-lg-3 nav-pills"
        ngbNav
        orientation="vertical"
        [(activeId)]="activeRoute"
        (navChange)="activeRouteChanged()"

    >
        <li class="mb-2 pb-1 w-100">
            <app-venue-selection
                [compact]="compact"
                class="w-100"
            >
            </app-venue-selection>
        </li>
        <ng-container
            *ngFor="let link of appMenu"
        >
            <li
                *ngIf="!link.hidden"
                class="menu-item"
                [ngbNavItem]="link.route"
            >
                <a
                    ngbNavLink
                    class="btn"
                    [routerLink]="link.route"
                    [ngbTooltip]="compact ? link.title : null"
                    triggers="hover"
                    placement="right"
                >
                    <ng-container
                        [ngTemplateOutlet]="menuItem"
                        [ngTemplateOutletContext]="{
                            $implicit: link,
                            compact: compact
                        }"
                    >
                    </ng-container>
                </a>
            </li>
        </ng-container>
        <li>
            <hr class="my-2"/>
        </li>
        <li
            *ngFor="let link of accountMenu"
            class="menu-item"
            [ngbNavItem]="link.route"
        >
            <a
                ngbNavLink
                class="btn"
                [routerLink]="link.route"
                [ngbTooltip]="compact ? link.title : null"
                triggers="hover"
                placement="right"
            >
                <ng-container
                    [ngTemplateOutlet]="menuItem"
                    [ngTemplateOutletContext]="{
                        $implicit: link,
                        compact: compact,
                    }"
                >
                </ng-container>
            </a>
        </li>
    </ul>
    </div>
</ng-template>

<ng-template #offCanvasMenu>
    <ng-template #content>
        <ng-container
            *ngTemplateOutlet="inlineMenu"
        >
        </ng-container>
        <div class="mt-auto">
            <ng-container
                [ngTemplateOutlet]="settings"
                [ngTemplateOutletContext]="{compact: compact}"
            >
            </ng-container>
        </div>
    </ng-template>
    <button
        class="btn btn-outline-secondary d-flex position-relative px-2"
        (click)="openOffCanvasMenu(content)"
    >
        <img
            ngSrc="assets/menu/normal.svg"
            alt="Settings"
            width="24"
            height="24"
        >
        <div
            class="position-absolute"
            style="right: -8px; top: -8px;"
        >
            <ng-template
                [ngTemplateOutlet]="badge"
                [ngTemplateOutletContext]="{$implicit: requestedBookingsCountPublisher}"
            >
            </ng-template>
        </div>
    </button>
</ng-template>

<ng-template
    #settings
    let-compact="compact"
>
    <div class="p-3 vstack gap-2 text-nowrap">
        <ng-container
            *ngIf="toolbarService.toolbarPlacement$ | async as toolbarPlacement"
        >
            <a
                *ngIf="toolbarPlacement === 'top'"
                type="button"
                class="btn btn-navigation hstack gap-3"
                (click)="showSideMenu()"
            >
                <img
                    ngSrc="/assets/pin/normal.svg"
                    width="24"
                    height="24"
                >
                <span class="flex-grow-1">
                    Pin Sidebar
                </span>
            </a>
            <a
                *ngIf="toolbarPlacement === 'side'"
                type="button"
                class="btn btn-navigation hstack gap-3"
                [ngbTooltip]="compact ? 'Unpin Sidebar' : null"
                triggers="hover"
                (click)="hideSideMenu()"
            >
                <img
                    ngSrc="/assets/pin/active.svg"
                    width="24"
                    height="24"
                >
                <span
                    *ngIf="!compact"
                    class="flex-grow-1"
                >
                    Unpin Sidebar
                </span>
            </a>
        </ng-container>
        @if (showCompactToggle$ | async) {
            <a
                *ngIf="!compact"
                type="button"
                class="d-none d-lg-flex btn btn-navigation hstack gap-3"
                (click)="menuCollapseClicked()"
            >
                <img
                    src="/assets/menu/hide.svg"
                >
                <span class="flex-grow-1">
                    Mini Sidebar
                </span>
            </a>
            <a
                *ngIf="compact"
                type="button"
                class="d-none d-lg-flex btn btn-navigation hstack gap-3"
                ngbTooltip="Expand Sidebar"
                triggers="hover"
                placement="right"
                (click)="menuExpandClicked()"
            >
                <img
                    src="/assets/menu/expand.svg"
                >
            </a>
        }
        <app-feedback
            [compact]="compact"
            class="text-center"
        >
        </app-feedback>
        <hr class="my-2"/>
        <a
            type="button"
            class="btn btn-navigation hstack gap-3"
            data-testid="logout-button"
            [ngbTooltip]="compact ? 'Log Out' : null"
            triggers="hover"
            placement="right"
            (click)="logout()"
        >
            <img
                src="/assets/logout/normal.svg"
            >
            <span
                *ngIf="!compact"
                class="flex-grow-1"
            >
                Log Out
            </span>
            <span
                *ngIf="compact"
                class="d-lg-none"
            >
                Log Out
            </span>
        </a>
    </div>
</ng-template>

<ng-template
    #menuItem
    let-link
    let-compact="compact"
>
    <div
        class="position-relative d-flex gap-3 justify-content-start align-items-center"
        [attr.data-testid]="link.route + '-menu-item'"
    >
        <img
            class="selected"
            [src]="'/assets/' + link.icon + '/active.svg'"
        >
        <img
            class="normal"
            [src]="'/assets/' + link.icon + '/normal.svg'"
        >
        <span
            *ngIf="!compact"
            class="text-nowrap"
        >
            {{ link.title }}
        </span>
        <span
            *ngIf="compact"
            class="d-lg-none"
        >
            {{ link.title }}
        </span>
        <ng-template
            [ngTemplateOutlet]="badgeWithCompactLargeInCorner"
            [ngTemplateOutletContext]="{$implicit: link}"
        >
        </ng-template>
    </div>
</ng-template>

<ng-template #badgeWithCompactLargeInCorner let-link>
    <div
        *ngIf="!compact"
        class="d-flex ms-auto"
    >
        <ng-template
            [ngTemplateOutlet]="badge"
            [ngTemplateOutletContext]="{$implicit: link.notificationCountPublisher}"
        >
        </ng-template>
    </div>
    <div
        *ngIf="compact"
        class="d-flex d-lg-none ms-auto"
    >
        <ng-template
            [ngTemplateOutlet]="badge"
            [ngTemplateOutletContext]="{$implicit: link.notificationCountPublisher}"
        >
        </ng-template>
    </div>
    <div
        *ngIf="compact"
        class="position-absolute d-none d-lg-flex"
        style="right: -8px; top: -8px;"
    >
        <ng-template
            [ngTemplateOutlet]="badge"
            [ngTemplateOutletContext]="{$implicit: link.notificationCountPublisher}"
        >
        </ng-template>
    </div>
</ng-template>

<ng-template #badge let-notificationCountPublisher>
    <ng-container
        *ngIf="notificationCountPublisher"
    >
        <div
            *ngIf="notificationCountPublisher.number$ | async as count"
            class="badge bg-danger top-0"
        >
            {{ count | notificationCount }}
        </div>

    </ng-container>
</ng-template>
