import { BillingDetails } from '@app/domain/BillingDetails'
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core'

@Component({
    selector: 'app-expired-banner',
    templateUrl: './expired-banner.component.html',
    styleUrls: ['./expired-banner.component.sass'],
})
export class ExpiredBannerComponent implements OnInit, OnChanges {

    @Input() billingDetails!: BillingDetails
    @Output() resumeSubscriptionClicked = new EventEmitter<void>()
    bannerShown!: boolean

    ngOnInit() {}

    ngOnChanges() {
        this.refreshBanner()
    }

    clickResumeSubscription() {
        this.resumeSubscriptionClicked.emit()
    }

    private refreshBanner() {
        this.bannerShown = !this.billingDetails.inService
    }
}
